@import "../_global";

.inputLarge {
  @include flex-center-center;
  margin: 10px auto;
  max-width: 400px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.checkboxCountries {
  .title {
    width: 100%;
    max-width: 410px;
    @include flex-start-center;
    .text {
      color: $gray;
      font-weight: 400;
    }
    .line {
      flex-grow: 2;
      border-bottom: 1px solid $whiteGray;
      margin-left: 20px;
    }
  }
  .checkboxes {
    max-width: 400px;
    padding: 0 20px;
    margin-top: 10px;
    display: flex;
    flex-wrap: wrap;
    border-left: 1px solid $whiteGray;
  }
}

.footerFM {
  width: 100%;
  @include flex-between-center;
  .left {
    @include flex-start-center;
  }
  .right {
    margin-right:20px;
  }
  margin-left: 10px;
}

.headerFM {
  margin: 10px 0;
  @include flex-between-center;
  .path {
    font-weight: 500;
    font-size: 16px;
  }
  .buttonsHead {
    @include flex-between-center;
  }
}

.addImage, .addFolder {
  input {
    display: none;
  }
}
.borderFM {
  position: relative;
  width: 850px;
  left: -24px;
  border-bottom: 1px solid #ececec;
}

.hierarchy {
  @include flex-between-stretch;

  position: relative;
  margin-top: 10px;

  .folders {
    width: 33%;
    padding: 7px 5px;
    box-sizing: border-box;
    border-right: 1px solid #ececec;
  }

  .files {
    &::-webkit-scrollbar {
      width: 2px;
      background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #3d9df3;
      border-radius: 40%;
    }
    width: 67%;
    @include flex-start-start;
    gap: 10px 10px;
    flex-wrap: wrap;
    box-sizing: border-box;
    padding: 2px 5px;
    overflow: hidden;
    height: 300px;
    overflow-y: auto;
    position: relative;
    .loadingMain {
      position: absolute;
      top: 0;
      left: 0;
      height: 300px;
      width: 100%;
      background: rgba(0, 0, 0, 0.05);
      @include flex-center-center;
    }
    .active {
      background: rgba(61, 157, 243, 0.31);
    }
    .imageWrapper {
      width: 120px;
      height: 140px;
      position: relative;
      border-radius: 3px;
      cursor: pointer;
      &:hover {
        background: rgba(61, 157, 243, 0.31);
        color: white;
        .close {
          display: block;
        }
      }
      .close {
        position: absolute;
        top: 3px;
        right: 4px;
        display: none;
        color: $white;
        svg {
          font-size: 14px;
        }
      }
      @include flex-center-center;
      .container {
        width: 110px;
        height: 130px;
        border-radius: 3px;

        .image {
          height: 110px;
          border-radius: 3px;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 3px;
          }
        }

        .name {
          height: 20px;
          font-size: 12px;
          text-align: center;
          text-wrap: inherit;
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }
    }

    .emptyFolder {
      height: 300px;
      width: 100%;
      @include flex-center-center;
    }
  }


  .rightModal {
    position: absolute;
    top: -1px;
    right: 0;
    width: 0;
    height: 0;
    background: $white;
    opacity: 0;
    visibility: hidden;
    transition: all .3s ease-in-out;
    @include flex-center-center;
    flex-direction: column;

    .textFieldFolder {
      .label {
        margin-bottom: 10px;
        font-weight: 500;
        margin-top: 20px;
      }
      margin: 10px auto;
      .buttonFolder {
        display: flex;
        justify-content: end;
        margin-top: 10px;
      }
    }

    .deleteFolder {
      padding: 20px;
      .title {
        font-size: 20px;
        font-weight: 500;
      }
      .question {
        padding: 20px 0;
        border-bottom: 1px solid #ececec;
        border-top: 1px solid #ececec;
      }
      .buttons {
        margin-top: 7px;
        @include flex-end-center;
      }
    }

    .hideModal {
      position: absolute;
      @include flex-start-center;
      cursor: pointer;
      top: 0;
      left: 10px;

      &:hover {
        .text {
          color: gray;
        }

        .icon {
          color: gray;
        }
      }

      .icon {
        background: #f3f3f3;
        @include flex-center-center;
        border-radius: 50%;

        svg {
          font-size: 17px;
        }
      }

      .text {
        margin-left: 4px;
        margin-bottom: 2px;
        font-size: 13px;
        @include flex-center-center;
      }
    }
  }

  .right_modal_open {
    opacity: 1;
    visibility: visible;
    width: 560px;
    height: 300px;
    transition: all .3s ease-in-out;
  }
}

.footerModalCustom {
  width: 100%;
  text-align: center;
  font-weight: 400;
  font-size: 16px;
  font-family: 'Futura PT';
}
.itemModalCustom {
  cursor: pointer;
  margin: 20px auto;
  height: 60px;
  width: 320px;
  border-radius: 3px;
  img {
    height: 100%;
    width: 100%;
  }
  &:hover {
    background: rgba(24, 144, 255, 0.06);
    box-shadow: 1px 0px 8px 7px rgba(24, 144, 255, 0.11);
  }
}

.exitViewButton {
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 999;
  path {
    cursor: pointer;
    stroke: gray;
    height: 28px;
    width: 28px;
  }
}
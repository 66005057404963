@import "../_global";

.stories_container {
  width: 100%;
  height: 100%;
  min-height: 30vw;
  display: flex;
  flex-direction: row;
  gap: 10px;

  .stories_slider {
    width: 50%;
    height: 100%;

    .img_slider {
      width: 100%;
      height: auto;
      display: block;
    }
  }

  .stories_tools {
    width: 50%;
    height: 100%;
    padding: 0 5px;

    .tools_box {
      width: auto;
      display: flex;
      flex-direction: column;
      gap: 10px;
    }

    .stories_actions {
      width: 100%;
      display: flex;
      flex-direction: row;
      gap: 5px;
    }

    .categories_box {
      width: 100%;
      height: auto;
      margin-top: 20px;
      margin-bottom: 20px;

      .label {
        margin-bottom: 9px;
      }

      .input {
        width: 100%;
        display: flex;
        align-items: center;
        flex-direction: row;

        button {
          background-color: #69b1ff;
          color: #ffffff;
        }
      }

      .categories_list {
        list-style: none;
        margin-top: 10px;
        padding: 0;

        max-height: 230px;
        overflow-y: auto;

        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 5px;

        li {
          width: auto;
          padding: 5px 10px;
          display: flex;
          align-items: center;
          border: 1px solid #bfbfbf;
        }
      }
    }
  }
}


.footer {
  width: 100%;
  display: flex;
  justify-content: flex-end;

  button {
    width: 250px;
    color: #ffffff;
    background-color: #2f54eb;
  }
}

@import "./_global.scss";
.app {
  display: flex;
  .main {
    width: 100%;
  }
}


.hide {
  display: none !important;
}
.p_0 {
  padding: 0 !important;
}

.m-15 {
  margin: 15px;
}

.mx-15 {
  margin: 0 15px 0 15px;
}

.mr-10 {
  margin: 0 10px 0 0;
}

.mr-7 {
  margin: 0 7px 0 0;
}

.mt-5 {
  margin-top: 5px;
}

.mt-15 {
  margin-top: 15px;
}

.mt--5 {
  margin-top: -15px;
}

.mb--5 {
  margin-bottom: -5px;
}


.p-relative {
  position: relative;
}
.d-block {
  display: block
}
.d-flex {
  display: flex;
  align-items: center;
}
.w-100 {
  width: 100%;
}
.h-90 {
  height: 90%;
}
.b-box {
  box-sizing: border-box;
}
.ov-hidden {
  overflow: hidden;
}


.hover-blue {
  cursor: pointer;
  color: #3b3b3b;
  &:hover {
    color: #1890ff;
  }
}

.white-gray {
  color: #cecece;
}
.red {
  color: #f5222d;
}
.blue {
  color: #1890ff;
}

.f-icon {
  cursor: pointer;
}
.fs-20 {
  font-size: 20px;
}
.fs-17 {
  font-size: 17px;
}

.fs-15 {
  font-size: 15px;
}
.fs-11 {
  font-size: 11px !important;
}
.fs-13 {
  font-size: 13px !important;
}
.fw-500 {
  font-weight: 900;
}

.custom-ant {
  .ant-btn {
    display: flex !important;
    justify-content: center;
    align-items: center;
  }
}
.ant-radio-button-wrapper {
  line-height: 34px !important;
}
.ant-picker {
  width: 100%;
}

.poster-video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  transition: opacity 0.1s linear;
  will-change: opacity;
  top: 0;
  left: 0;
  z-index: 100;
  &.hide {
    opacity: 0;
    transition: opacity 0.1s linear;
    will-change: opacity;
    z-index: 0;
  }
}

.img-opacity-animate {
  opacity: 1;
  will-change: opacity;
  transition: all 0.1s linear;
  &.active {
    opacity: 0;
  }
}

.animate-will-change-transform-opacity {
  will-change: opacity
}

.animate-will-change-page {
  will-change: opacity
}


.slider-width-progress {
  .own-progress {
    display: block;
    position: absolute;
    height: 2px;
    width: 100%;
    left: 0;
    z-index: 10;
    bottom: 1px;
    .bar-transition, .bar-transition-first {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 0;
      height: 100%;
      z-index: 1;
      background-color: black;
      will-change: width;
    }
  }
  .swiper-slide-active {
    .bar-transition {
      transition: width 6s ease 2s;
      width: 100%;
    }
    .bar-transition-first {
      transition: width 6s ease 0s;
      width: 100%;
    }
  }
  .swiper-slide-duplicate {
    .bar-transition-first {
      transition: none !important;
      width: 0 !important;
    }
  }
}
.display_contents {
  overflow: visible;
  display: contents;
}
video {
  object-fit: cover;
}
.swiperHalf {
  .swiper-slide {
    width: 100% !important;
  }
}


///colors for table templates
.dark-blue {
  background: #2c91d5;
}
.dark-blue-pale {
  background: #e9f4fa;
}
@import "./styles/_global";
body {
  margin: 0;
  color: $darkGray;
  font-family: 'Futura PT', 'Bodoni Cyrillic', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'Bodoni Cyrillic';
  src: url('./fonts/Bodoni/Bodoni-Cyrillic.ttf');
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url('./fonts/Bodoni/Bodoni-Cyrillic.woff') format('woff'),
  url('./fonts/Bodoni/Bodoni-Cyrillic.ttf') format('truetype');
}
@font-face {
  font-family: 'Bodoni Cyrillic';
  src: url('./fonts/Bodoni/Bodoni-Cyrillic.woff2') format('truetype');
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Bodoni Cyrillic';
  src: url('./fonts/Bodoni/Bodoni-Cyrillic-f.woff2') format('truetype');
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Bodoni Cyrillic';
  src: url('./fonts/Bodoni/Bodoni-Cyrillic-s.woff2') format('truetype');
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Futura PT';
  src: url('./fonts/FuturaPT/FuturaPT-Heavy.eot');
  src: url('./fonts/FuturaPT/FuturaPT-Heavy.eot?#iefix')
  format('embedded-opentype'),
  url('./fonts/FuturaPT/FuturaPT-Heavy.woff2') format('woff2'),
  url('./fonts/FuturaPT/FuturaPT-Heavy.woff') format('woff'),
  url('./fonts/FuturaPT/FuturaPT-Heavy.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}



@font-face {
  font-family: 'Futura PT';
  src: url('./fonts/FuturaPT/FuturaPT-Medium.eot');
  src: url('./fonts/FuturaPT/FuturaPT-Medium.eot?#iefix')
  format('embedded-opentype'),
  url('./fonts/FuturaPT/FuturaPT-Medium.woff2') format('woff2'),
  url('./fonts/FuturaPT/FuturaPT-Medium.woff') format('woff'),
  url('./fonts/FuturaPT/FuturaPT-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Linearicons';
  src: url('./fonts/Icons/Linearicons.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}


@font-face {
  font-family: 'Futura PT';
  src: url('./fonts/FuturaPT/FuturaPT-Book.eot');
  src: url('./fonts/FuturaPT/FuturaPT-Book.eot?#iefix')
  format('embedded-opentype'),
  url('./fonts/FuturaPT/FuturaPT-Book.woff2') format('woff2'),
  url('./fonts/FuturaPT/FuturaPT-Book.woff') format('woff'),
  url('./fonts/FuturaPT/FuturaPT-Book.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Futura PT';
  src: url('./fonts/FuturaPT/FuturaPT-Light.eot');
  src: url('./fonts/FuturaPT/FuturaPT-Light.eot?#iefix')
  format('embedded-opentype'),
  url('./fonts/FuturaPT/FuturaPT-Light.woff2') format('woff2'),
  url('./fonts/FuturaPT/FuturaPT-Light.woff') format('woff'),
  url('./fonts/FuturaPT/FuturaPT-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'No Name';
  src: url('./fonts/no_name_37/11693.eot');
  src: url('./fonts/no_name_37/11693.eot?#iefix') format('embedded-opentype'),
  url('./fonts/no_name_37/11693.woff2') format('woff2'),
  url('./fonts/no_name_37/11693.woff') format('woff'),
  url('./fonts/no_name_37/11693.otf') format('opentype'),
  url('./fonts/no_name_37/11693.ttf') format('truetype');
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Dubai Light';
  src: url('./fonts/dubai_light/dubai-light.eot');
  src: url('./fonts/dubai_light/dubai-light.eot?#iefix') format('embedded-opentype'),
  url('./fonts/dubai_light/dubai-light.woff2') format('woff2'),
  url('./fonts/dubai_light/dubai-light.woff') format('woff'),
  url('./fonts/dubai_light/dubai-light.otf') format('opentype'),
  url('./fonts/dubai_light/dubai-light.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'AnastasiaScript';
  src: url('./fonts/AnastasiaScript/anastasiascript.eot');
  src: url('./fonts/AnastasiaScript/anastasiascript.eot?#iefix') format('embedded-opentype'),
  url('./fonts/AnastasiaScript/anastasiascript.woff2') format('woff2'),
  url('./fonts/AnastasiaScript/anastasiascript.woff') format('woff'),
  url('./fonts/AnastasiaScript/anastasiascript.otf') format('opentype'),
  url('./fonts/AnastasiaScript/anastasiascript.ttf') format('truetype');
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Venski Sad One';
  src: url('./fonts/VenskiSadOne/VenskiSadOne.ttf') format('truetype');
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Helvetica Bold';
  src: url('./fonts/Helvetica/Helvetica-Bold.eot');
  src: url('./fonts/Helvetica/Helvetica-Bold.eot?#iefix') format('embedded-opentype'),
  url('./fonts/Helvetica/Helvetica-Bold.woff2') format('woff2'),
  url('./fonts/Helvetica/Helvetica-Bold.woff') format('woff'),
  url('./fonts/Helvetica/Helvetica-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Cinzel';
  src: url('./fonts/Cinzel/cinzel_bold.ttf') format('truetype'),
  url('./fonts/Cinzel/cinzel_bold.woff') format('woff'),
  url('./fonts/Cinzel/cinzel_bold.otf') format('opentype'),
  url('./fonts/Cinzel/cinzel_bold.eot') format('embedded-opentype'),
  url('./fonts/Cinzel/cinzel_bold.woff2') format('woff2');
  font-style: normal;
  font-weight: 700;
  font-display: swap;
}

@font-face {
  font-family: 'Cinzel';
  src: url('./fonts/Cinzel/cinzel_regular.ttf') format('truetype'),
  url('./fonts/Cinzel/cinzel_regular.woff') format('woff'),
  url('./fonts/Cinzel/cinzel_regular.otf') format('opentype'),
  url('./fonts/Cinzel/cinzel_regular.eot') format('embedded-opentype'),
  url('./fonts/Cinzel/cinzel_regular.woff2') format('woff2');
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: 'CormorantGaramond';
  src: url('./fonts/CormorantGaramond/CormorantGaramond-Bold.ttf') format('truetype'),
  url('./fonts/CormorantGaramond/CormorantGaramond-Bold.ttf') format('embedded-opentype'),
  url('./fonts/CormorantGaramond/CormorantGaramond-Bold.woff2') format('woff2'),
  url('./fonts/CormorantGaramond/CormorantGaramond-Bold.otf') format('opentype'),
  url('./fonts/CormorantGaramond/CormorantGaramond-Bold.woff') format('woff');
  font-style: normal;
  font-weight: 700;
  font-display: swap;
}

@font-face {
  font-family: 'CormorantGaramond';
  src: url('./fonts/CormorantGaramond/CormorantGaramond-Italic.ttf') format('truetype'),
  url('./fonts/CormorantGaramond/CormorantGaramond-Italic.ttf') format('embedded-opentype'),
  url('./fonts/CormorantGaramond/CormorantGaramond-Italic.woff2') format('woff2'),
  url('./fonts/CormorantGaramond/CormorantGaramond-Italic.otf') format('opentype'),
  url('./fonts/CormorantGaramond/CormorantGaramond-Italic.woff') format('woff');
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'CormorantGaramond';
  src: url('./fonts/CormorantGaramond/CormorantGaramond-Regular.ttf') format('truetype'),
  url('./fonts/CormorantGaramond/CormorantGaramond-Regular.ttf') format('embedded-opentype'),
  url('./fonts/CormorantGaramond/CormorantGaramond-Regular.woff2') format('woff2'),
  url('./fonts/CormorantGaramond/CormorantGaramond-Regular.otf') format('opentype'),
  url('./fonts/CormorantGaramond/CormorantGaramond-Regular.woff') format('woff');
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: 'Avenir Next Cyr Light';
  src: url('fonts/avenir-next/AVENIRNEXTCYR-LIGHT.ttf') format('truetype'),
  url('fonts/avenir-next/AVENIRNEXTCYR-LIGHT.ttf') format('embedded-opentype'),
  url('./fonts/avenir-next/AVENIRNEXTCYR-LIGHT.woff2') format('woff2'),
  url('./fonts/avenir-next/AVENIRNEXTCYR-LIGHT.otf') format('opentype'),
  url('./fonts/avenir-next/AVENIRNEXTCYR-LIGHT.woff') format('woff');
  font-style: normal;
  font-display: swap;
}

.font_b {
  font-family: 'Bodoni Cyrillic';
  font-weight: 400;
  font-size: 40px;
}

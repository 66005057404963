@import "../_global";

.storyContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  width: calc(100vw - 350px);
  height: calc(100vh - 90px);
  position: fixed;
  top: 80px;
  left: 10px;
  bottom: 10px;
  right: 340px;

  .template {
    display: flex;
    gap: 20px;
    height: calc(100vh - 90px);
    width: calc(100vw - 350px);

    .collection {
      overflow-y: auto;
      width: 200px;
      padding: 30px 0;

      .itemList {
        padding: 0 30px;
        height: 100%;
        overflow-y: auto;
        overflow-x: hidden;

        .addItemButton, .item {
          width: 140px;
          height: 140px;
          box-sizing: border-box;
          box-shadow: rgba(0, 0, 0, 0.09) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;
          @include flex-start-center;

          .icons {
            width: 140px;
            height: 140px;
            position: relative;
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
            transition: all 0.2s ease-in-out;
            filter: brightness(50%);

            &:hover {
              transform: scale(1.1);
              filter: brightness(100%);

              .upload {
                transition: all 0.2s ease-in-out;
                background: rgba(255, 255, 255, 0.5);
              }

              .plus {
                color: #ffffff;
                font-size: 40px;
                margin-top: -20px;
                transition: all 0.2s ease-in-out;
              }
            }

            @include flex-center-center;
            flex-direction: column;
            cursor: pointer;

            &:hover {
              border-color: #1790ff;
            }

            .upload {
              font-weight: 500;
              transition: all 0.2s ease-in-out;
              background: rgba(255, 255, 255, 0.29);
            }

            .plus {
              margin-top: -7px;
              font-size: 25px;
              font-weight: 500;
              color: $lightGray;
              transition: all 0.2s ease-in-out;
            }
          }
        }

        .item {
          margin-bottom: 30px;
        }

        .addItemButton {
          .icons {
            filter: brightness(100%);
          }
        }
      }
    }

    .storyEditField {
      background: #ededed;
      width: calc(100vw - 570px);
      height: calc(100vh - 90px);
      overflow: hidden;
      box-sizing: border-box;
      padding: 0 30px;

      .storyBlockWrapper {
        width: 100%;
        margin: 30px auto;
        max-width: 900px;

        img {
          width: 100%;
        }
      }
    }
  }
}

.workFieldContainer {
  /*  overflow: hidden;*/
  padding: 80px 340px 10px 10px;
  box-sizing: border-box;
  min-height: 100vh;
  width: 100%;
  background: $white;

  .tabletBG {
    width: 820px;
    height: 1100px;
    margin: 75px auto 0 auto;
    overflow: hidden;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 0;
      background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
      width: 0;
    }
  }

  .phoneBG {
    width: 440px;
    position: relative;

    height: 877px;
    margin: 25px auto 0 auto;
    overflow: hidden;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 0;
      background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
      width: 0;
    }
  }

  .workFieldArea {
    transition: all 0.2s cubic-bezier(0.25, 0.1, 0.25, 1);
    will-change: transform;

    &.hideArea {
      transform: scale(0);
    }

  }

  .workField {
    /*    visibility: hidden;*/
    background: $whiteGray;
    min-height: 90vh;
    margin: auto;
    width: 100%;
    transition: width .5s;
  }

  .tablet {
    width: 820px;
    transition: width .5s;
  }

  .phone {
    width: 414px;
    transition: width .5s;
  }
}

.mosaicCardHide {
  opacity: 0;
}

.mosaicCardShow {
  opacity: 1;
  transition: opacity 0.6s cubic-bezier(0.25, 0.1, 0.25, 1);
  will-change: opacity;
}

.minContainer {
  width: calc(100% - (2 * 220px));
  margin: 0 auto;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.minContainerSm {
  max-width: calc(100% - (2 * 20px));
  margin: 0 auto;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.container {
  position: relative;
  width: 100%;
  display: grid;
  margin: 0;

  &.minContainer {
    width: calc(100% - (2 * 220px));
    margin: 0 auto;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &.minContainerSm {
    max-width: calc(100% - (2 * 20px));
    margin: 0 auto;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .itemContainer {
    position: relative;

    .imgSize {
      height: inherit;
      box-sizing: border-box;

      img {
        object-fit: cover !important;
        max-width: 100%;
        max-height: 100%;
      }
    }

    .imgSizeOutside {
      height: inherit;
      box-sizing: border-box;
      display: block;
      position: relative;
      text-decoration: none;
      margin-left: auto;
      margin-right: auto;
      max-width: calc(((5 / 5) * 100%) - (68px - (5 / 12 * 68px)));

      img {
        display: block;
        position: relative;
        max-width: 100%;
        width: 100%;
      }
    }

    .imgSizeOutsideSm {
      height: inherit;
      box-sizing: border-box;
      display: block;
      position: relative;
      text-decoration: none;
      margin-left: auto;
      margin-right: auto;
      width: 100%;
      max-width: calc(((6 / 8)* 100%) - (20px - (6 / 8 * 20px)));

      img {
        display: block;
        position: relative;
        max-width: 100%;
        width: 100%;
      }
    }
  }
}

.swiperIndex {
  position: absolute;
  top: 2px;
  left: 2px;
  height: 32px;
  width: 32px;
  font-size: 16px;
  border-radius: 50%;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #000000;
}

.swiperContainer {
  &.halfType {
    width: 100%;
    height: 100%;
    display: grid;
  }

  margin: 0px 0 -0.5px 0px;

  .itemContainer {
    position: relative;
    margin: 0 1px;

    .imgSize {
      img {
        width: 100%;
      }
    }
  }
}

.cardColor {
  box-sizing: border-box;
  position: absolute;
  top: 5px;
  right: 0;
  display: flex;
  align-items: center;
  opacity: 0;
  visibility: hidden;
  transition: opacity .5s cubic-bezier(0.25, 0.1, 0.25, 1);
  will-change: opacity;

  &.cardColorsHover {
    opacity: 1;
    visibility: visible;
  }

  .color {
    display: block;
    margin-right: 5px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    z-index: 7;

    &.light {
      border: 1px solid $black;
    }
  }
}

.frontSpace {
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 0;
  height: 100%;
  z-index: 8;
  width: 100%;
  transition: all 0.8s ease;

  &.outsideSpace {
    position: relative;
    top: inherit;
    height: auto;
  }

  .inversed_place {
    align-self: center;
    position: sticky;
    bottom: 0px;
    text-transform: uppercase;
    display: block;

    .invert {
      background-size: 100%;
      background-repeat: repeat;
    }

    .btn {
      border: 1px solid transparent;
    }

    .innerTextBtnSticky {
      display: inline-block;
      padding: 10px 30px;
      height: 100%;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    .text {
      text-align: center;
      font-size: 40px;
      margin: 0px auto;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }

  .frontSpaceWrapper {
    position: relative;

    .textHover {
      position: relative;
      bottom: -50px;
      opacity: 0;
      transition: all 0.8s ease;
    }

    .textHoverActive {
      bottom: 0;
      opacity: 1;
      transition: all 0.8s ease;
    }
  }
}

.hoverBackground {
  &:hover {
    background-size: 115%;
  }
}

.appearanceBackground {
  transition: all 2s cubic-bezier(0.5, 0, 0, 1);
  background-size: 125%;
}

.appearanceBackgroundEnd {
  background-size: 101%;
}

.buttonOutline, .buttonUnderline {
  position: relative;
  display: block;
  height: auto;
  padding: 2px 30px;
  border: 1px solid;
  text-align: center;
  background-color: transparent;
  text-decoration: none;
  cursor: pointer;
  opacity: 1;
  transition: all .2s;

  &:hover {
    opacity: .5;
  }
}

.buttonUnderline {
  border-top: none;
  border-right: none;
  border-left: none;
  padding: 12px 2px;
  text-transform: none;
  line-height: 19px;
}

.half_block {
  height: 30vw;
  @media screen and (max-width: 768px) {
    height: 40vw !important;
  }
}

.full_block {
  height: 100vh !important;
}

.gridHalf {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.mosaic_countdown_container {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  height: auto;
  position: relative;

  .display {
    width: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 15px;

    .display_value {
      display: flex;
      position: relative;
    }

    .display_dot {
      position: absolute;
      top: -12px;
      right: -16px;
      width: 5px;
      margin-left: 10px;
    }

    .display_type {
      text-transform: uppercase;
    }
  }
}

.container_fix_width {
  width: calc(1440px - (2 * 112px));
  margin: 0 auto;

  @media screen and (max-width: 1399.98px) {
    width: calc(100% - (2 * 122px));
  }

  @media screen and (max-width: 768px) {
    width: calc(100% - (2 * 20px));
  }
}

.mosaic_saint_laurent_block {
  height: auto !important;
  position: relative;
  margin-bottom: 0;
  max-width: 100%;

  @media screen and (max-width: 1199.98px) {
    margin-left: auto;
    margin-right: auto;
    max-width: calc(((6 / 8) * 100%) - (20px - (6 / 8 * 20px)));
  }

  figure {
    aspect-ratio: 2/3;
    margin-top: 0;
    margin-bottom: 0;
    max-height: calc(min(100vh, 100dvh) - 54px - 10.5rem);

    &.first {
      margin-right: 0;
      margin-left: auto;
    }

    &.second {
      margin-left: 0;
      margin-right: auto;
    }

    &.single {
      margin: 0 auto;
    }
  }
}

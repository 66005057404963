@import "../_global";

.rightbarContainer {
  background: $white;
  position: fixed;
  top: 70px;
  right: 0;
  width: 330px;
  height: calc(100vh - 50px);
  z-index: 9;
  .viewMode {
    margin: 10px 15px;
    text-align: center;
    .head {
      background: #f2f9ff;
      font-size: 20px;
      font-weight: 500;
      margin-bottom: 20px;
    }
    .text {
      font-weight: 400;
      color: #555555;
    }
  }
  .sideContainer {
    background: $white;
    position: fixed;
    top: 70px;
    right: -330px;
    width: 330px;
    height: calc(100vh - 50px);
    overflow-y: auto;
    z-index: 9;
    transition: all .3s ease-in-out;
    &::-webkit-scrollbar {
      width: 0;
      background-color: transparent;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 0;
    }
    .emptyStyle {
      text-align: center;
      font-weight: 500;
      padding-top: 10px;
      color: #7f7f7f;
    }
    .sideHead {
      @include flex-between-center;
      height: 75px;
      .title {
        font-weight: 500;
        margin-left: 15px;
      }
      .close {
        margin-right: 10px;
        font-weight: 500;
        cursor: pointer;
        &:hover {
          color: #1790ff;
        }
      }
    }
  }
  .sideActive {
    transition: all .3s ease-in-out;
    right: 0px;
  }
  .rightbar {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 5px;
    &::-webkit-scrollbar {
      width: 4px;
      background-color: transparent;
    }
    &::-webkit-scrollbar-thumb {
      background-color: rgba(128, 128, 128, 0.5);
      border-radius: 2px;
    }
    .rightbarHeader {
      @include flex-between-center;
      width: 100%;
      border-bottom: 1px solid #999999;
      padding: 15px 0;
      .title {
        font-weight: 500;
        color: $black;
      }
      .setting {
        font-size: 20px;
        color: $lighterGray;
        cursor: pointer;
        &:hover {
          color: #1790ff;
        }
      }
    }
    .rightbarBody {
      font-size: 14px;
      color: $black;
      font-family: 'Futura PT';
      .emptyBody {
        padding: 20px;
        text-align: center;
        font-weight: 400;
      }
    }
  }
}

.templatesContainer {
  width: 330px;
  height: 100%;
  background-color: $white;
  box-sizing: border-box;
  overflow: hidden;
  .templates {
    margin: 20px;
    height: 90%;
    box-sizing: border-box;
    overflow-y: auto;
    @include flex-start-center;

    flex-wrap: wrap;
    &::-webkit-scrollbar {
      width: 4px;
      background-color: transparent;
    }
    &::-webkit-scrollbar-thumb {
      background-color: rgba(128, 128, 128, 0.5);
      border-radius: 2px;
    }
    .template {
      cursor: pointer;
      margin: 10px;
      width: 120px;
      min-width: 120px;
      box-sizing: border-box;
      border: 1px solid #1890ff;
      padding: 10px;
      border-radius: 2px;
      img {
        width: 64px;
        height: 46px;
        display: block;
        margin: 0 auto 15px;
      }
      .name {
        text-align: center;
        font-size: 12px;
        width: 100%;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
    }
  }
}

.addImage {
  margin: 15px;
  @include flex-start-center;
  .icons {
    width: 100px;
    height: 100px;
    border: 1px dashed #d6d6d6;
    position: relative;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    transition: all 0.2s linear;
    &:hover {
      .upload {
        transition: all 0.2s linear;
        background: rgba(255,255,255,0.5);
      }
      .plus {
        color: #ffffff;
        font-size: 40px;
        margin-top: -20px;
        transition: all 0.2s linear;
      }
    }
    @include flex-center-center;
    flex-direction: column;
    cursor: pointer;
    &:hover {
      border-color: #1790ff;
    }
    .upload {
      font-weight: 500;
      transition: all 0.2s linear;
      background: rgba(255,255,255,0.29);
    }
    .plus {
      margin-top: -7px;
      font-size: 25px;
      font-weight: 500;
      color: $lightGray;
      transition: all 0.2s linear;
    }
  }
  .inform {
    color: $lightGray;
    width: calc(100% - 100px);
    box-sizing: border-box;
    padding: 8px 15px;
    font-size: 13px;
  }
}


h3 {
  width: 100%;
  text-align: left;
  font-size: 14px;
  margin: 10px 15px;
}
.textAndInput {
  margin: 15px;
  @include flex-start-center;
  .label {
    min-width: 50%;
    font-weight: 500;
  }
}
.linkContainer {
  margin: 0px 20px 0 15px;
  .checkbox {
/*    margin-top: -18px;*/
  }
  .checkboxContainer {
    margin-top: -18px;
  }
  .checkboxLastDel {
    margin-top: -18px;
  }
}

.blockThemeContainer {
  margin: -10px 20px 0 15px;
}

.deadline {
  color: #f5222d;
  margin-top: 30px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border: 1px solid #f5222d;
}

.rightStylesMainMargin {
  margin: 15px;
}

.rightStylesMainMargin {
  margin: 15px;
}

.groupSelector {
  width: 300px;
  margin: auto;
}

.mainStyles {
  padding-left: 15px;
  margin: 25px 0;
  .title {
    font-weight: 500;
    margin-bottom: 15px;
  }
  .buttons {
    .rButton {
      padding: 0 8px;
      img {
        margin-top: -6px;
      }
    }
  }
  .helpers {
    font-weight: 400;
    color: #7f7f7f;
  }
}

.textAndSwitch {
  margin: 0 15px;
  @include flex-between-center;
  .label {
    font-weight: 500;
  }
}

.textAndButtoms {
  margin: 0 15px;
  @include flex-between-center;
  .label {
    font-weight: 500;
  }
  .buttomItems {
    @include flex-end-center;
  }
}
.buttomItemsC {
  @include flex-start-center;
  flex-wrap: wrap;
  margin: 0 15px;
  .inner {
    display: flex;
    justify-content: center;
    align-items: center;
    .innerText {
      display: flex;
      justify-content: center;
      align-items: center;
      padding-right: 7px;
      margin: 0;
    }
  }
}


.textAndLongInput {
  margin: 0 15px;
  @include flex-start-center;
  .label {
    min-width: 20%;
  }
  .input {
    width: 90%;
  }
}

.colorsAdd {
  margin: 20px 15px;
  @include flex-center-center;
  .left, .right {
    width: 50%;
  }
}

.inputColor {
  cursor: pointer;
  display: block;
  width: 25px;
  height: 25px;
  margin-right: 10px;
  line-height: 1;
  color: $black;
  background-color: $ghostWhite;
  border: none;
  border-radius: 0.25rem;
  outline: 0;
}


.listBlock {
  @include flex-between-center;
  flex-wrap: wrap;
  width: 300px;
  margin: 15px;
  .element {
    width: 45%;
    min-width: 45%;
    min-height: 30px;
    @include flex-center-center;
    .label {
      display: block;
      color: $black;
      width: 20%;
      @include flex-start-center;
    }
    .inputWrapper {
      @include flex-end-center;
      width: 100%;
      .input {
        width: 100%;
      }
    }
  }
}

.groupButtons {
  @include flex-start-center;
  padding: 10px 15px;
}

.block {
  @include flex-start-center;
  padding: 10px 15px;
  gap: 30px;
  flex-wrap: wrap;
}

.animate {
  .blocks {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: space-between;
    width: 300px;
    margin: auto;
    .block {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 140px;
      background: rgba(255, 255, 255, 0.07);
      border: 1px solid;
      border-color: #b8b8b8;
      cursor: pointer;
      border-radius: 3px;
      padding: 5px;
      .blockFlex {
        display: flex;
      }
      .text {
        font-weight: 500;
        text-wrap: wrap;
        max-width: 28%;
      }
      .photoBG, .photoTX, .photoBgStatic, .photoBGIncrease {
        width: 62px;
        height: 62px;
        border-radius: 3px;
        background-image: url('../../assets/settingImg.jpg');
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100%;
        transition: all 0.2s linear;
      }
      .photoBGIncrease {
        background-size: 120%;
      }
      .photoTX {
        @include flex-center-center;
        .TX  {
          position: relative;
          color: #ffffff;
          opacity: 0;
          top: 30px;
          transition: all 0.2s linear;
        }
      }
      &:hover {
        border-color: #686868;
        .photoBG {
          transition: all 0.2s linear;
          background-size: 120%;
        }
        .photoBGIncrease {
          transition: all 0.2s linear;
          background-size: 100%;
        }
        .photoTX {
          .TX {
            transition: all 0.2s linear;
            top: 0;
            opacity: 1;
          }
        }
      }
    }
    .blockActive {
      border-color: #1790ff;
      background: rgba(23, 144, 255, 0.07);
    }
  }
}


//animate classes

.flash {
  &:hover {
    animation: hoverFlash 1.2s cubic-bezier(0.25, 0.1, 0.25, 1);
    will-change: opacity;
  }
  @keyframes hoverFlash {
    from { opacity: 0; }
    to { opacity: 1 }
  }
}

.stories_modal {
  width: 100% !important;
  max-width: 900px !important;

  .ant-modal-content {
    height: 500px;
  }
}

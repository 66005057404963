//////////// COLORS ////////////
$white: #ffffff;
$ghostWhite: #f8f8fe;
$whiteGray: #eaeaea;
$lighterGray: #aab3b2;
$lightGray: #dbdbdb;
$gray: #404147;
$darkGray: #2c2d31;
$lightBlack: #24283b;
$black: #1a1d27;
$yellow: #FFCE46;
$blue: #3D9DF3;
$red: #ff0000;
